<template>
    <div class="layout--main">
        <v-navigation-drawer app>
            <v-list-item>
                <img class="menu-logo" alt="Vue logo" src="../assets/logo.png">

                <v-list-item-content>
                    <v-list-item-title class="title">
                        Application
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        subtext
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <router-link v-for="item in items"
                             :key="item.title"
                             :to="item.link"
                             class="custom-router-link"
                             :class="{'active': item.link === $route.fullPath }"
                >
                    <v-list-item

                        link
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

            </v-list>
        </v-navigation-drawer>

        <v-app-bar class="custom-app-bar" app>
            <v-menu
                bottom
                min-width="200px"
                rounded
                offset-y
            >
                <template v-slot:activator="{ on }">
                    <div v-on="on" class="user-info">
                        <v-avatar color="indigo">
                            <v-icon dark>
                                mdi-account-circle
                            </v-icon>
                        </v-avatar>
                        <div>{{ $store.state.user.username }}</div>
                    </div>
                </template>
                <v-card>
                    <v-list-item-content class="justify-center">
                        <div class="mx-auto text-center">
                            <v-avatar color="indigo">
                                <v-icon dark>
                                    mdi-account-circle
                                </v-icon>
                            </v-avatar>
                            <h3>{{ $store.state.user.username }}</h3>
                            <p class="caption mt-1">
                                {{ $store.state.user.email }}
                            </p>
                            <v-divider class="my-3"></v-divider>
<!--                            <v-btn-->
<!--                                depressed-->
<!--                                rounded-->
<!--                                text-->
<!--                            >-->
<!--                                Edit Account-->
<!--                            </v-btn>-->
<!--                            <v-divider class="my-3"></v-divider>-->
                            <v-btn
                                depressed
                                rounded
                                text
                                @click="logout"
                            >
                                Logout
                            </v-btn>
                        </div>
                    </v-list-item-content>
                </v-card>
            </v-menu>
        </v-app-bar>
        <v-main>
            <v-container fluid>
                <router-view @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)"/>
            </v-container>
        </v-main>
    </div>
</template>


<script>
    export default {
        components: {

        },
        data() {
            return {
                items: [
                    { title: 'Dashboard', icon: 'mdi-view-dashboard', link: '/' },
                    { title: 'Categories', icon: 'mdi-file-document', link: '/categories' },
                    { title: 'Tests', icon: 'mdi-file', link: '/tests' },
                    { title: 'Questions', icon: 'mdi-chat-question', link: '/questions' },
                    { title: 'Answers', icon: 'mdi-android-messages', link: '/answers' },
                    { title: 'Results', icon: 'mdi-forum', link: '/results' },
                    { title: 'Test passed', icon: 'mdi-file-sync', link: '/test-passeds' },
                ],
            }
        },
        watch: {

        },
        methods: {
            logout() {
                localStorage.removeItem('jwt');
                this.$store.commit("setState", {user: null});
                this.$router.push('/login')
            },
        },
        mounted() {

        }
    }

</script>

